* {
  box-sizing: border-box;
  margin: 0;
  padding:0;
}

body {
  overflow: hidden;
  background-color: whitesmoke;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header {
  background-color: #4cbb17;
  display: flex;
  height: 15vh;
}
.header > h1 {
  text-align: center;
  margin: 2rem auto;
  font-family: "Ubuntu";
  color: whitesmoke;
  letter-spacing: 3px;
}
.logo {
  width: 13%;
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  transition: all .5s ease-in-out;
}
.logo:hover { transform: scale(1.1); }
.paper {
  align-items: center;
  padding: 0 20px;
  height: auto;
}
.banner{

  height: 60vh;
  background-image: linear-gradient(to right,#65C18C , #C1F4C5,#65C18C );
  /* background-color: #C1F4C5; */
}
.banner-below, .banner-img{
  margin-top: 2rem;
  
}
.banner-img{
  border-radius: 49%;
  transition: all .5s ease-in-out;
}
.banner-img:hover { transform: scale(1.1); }
.banner-below > h3{
  padding-bottom: 1rem;
}
.form-container{
  margin-top: 2rem; 
} 

.back {
  margin-top: 4rem;
  margin-bottom: -4rem;
}
.submit {
  cursor: pointer;
  border-radius: 5em;
  color: #fff;
  background: linear-gradient(to right, #228b22, #32cd32);
  border: 0;
  padding: 10px 40px;
  margin-left: 30%;
  font-size: 15px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
}

.textfield-1{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
  margin: 10px auto;
  
}
.paper1 {
  align-items: center;
  height: auto;
  padding: 5px;
  margin-top: 4rem;
  margin-bottom: 3rem;
}
.app-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.title-sponsored {
  color: grey;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
}
.title2 {
  color: grey;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
  padding-bottom: 2rem;
  margin-top: 5rem;
}
.textfield {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 30px;
  margin: 10px;
}
.link {
  text-decoration: none;
  color: forestgreen;
}
.textfield > .error {
  font-size: 11px;
  font-weight: 600;
  display: block;
  color: red;
}
.steps {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: forestgreen;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  margin: 10px auto;
  
}

.success {
  color: green;
  margin: 5px auto;
  padding: 10px;
  text-align: center;
}
.app-btn2 {
  width: 250px;
  height: 10vh; 
}
.next {
  width: 210px;
  height: 7vh;
}
@media (max-width: 500px) {
  .header > h1 {
   font-size: 15px;
   margin-top: 3rem;
  }
  .textfield {
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin: -3rem auto;
  }
  .textfield > .box {
    margin-bottom: 30px;
    width: 100%;
  }
  .logo {
    width: 25%;
    height: 10vh;
    margin-top: 20px;
  }
  .next {
    width: 70%;
    height: 6vh;
  }
  
  .success {
    font-size: 15px;
  }
  .steps {
    margin-bottom: 50px;
  }
  
  
  
  .paper1 {
    align-items: center;
    padding: 40px 20px;
    overflow: auto;
  }
  
  .app-btn, .btn, .app-btn1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }
  .app-btn1 {
    width: 400px;
    padding: 20px;  
  }
  .app-btn2 {
    width: 85%;
    height: 7vh;
     
  }
  .paper2 {
    align-items: center;
    margin: 20px auto;
    height: auto;
    width: 100%;
  }
  .title2{
    font-size: 16px;
    margin-top: 8rem;
  }
  
}